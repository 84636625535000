<script>
import { mapActions } from 'vuex';
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/navbar/Navbar.vue';
import Cookies from '@/components/Cookies.vue';

export default {
  name: 'home',

  created() {
    this.$store.dispatch('misc/init');
    this.initCart();
  },

  methods: {
    ...mapActions('cart', {
      initCart: 'init',
    }),
  },

  components: {
    Navbar,
    'v-cookies': Cookies,
    'v-footer': Footer,
  },
};
</script>

<template>
  <div id="app">
    <navbar class="navbar" />
    <router-view class="main-view bg-gray-100" :key="$route.fullPath" />
    <v-cookies class="cookies"/>
    <v-footer class="footer" />
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

#app {
 font-family: "Open Sans", sans-serif;
  // font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  max-height: 100%;
  min-height: 100vh;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;



  .main-view {
    flex: 1;
    min-width: 100%;
  }
}
</style>
