import Vue from 'vue';
import LangRouter from 'vue-lang-router';
import translations from '../src/lang/translations';
import localizedURLs from '../src/lang/localized-urls';

Vue.use(LangRouter, {
  defaultLanguage: 'se',
  translations,
  localizedURLs,
});

const router = new LangRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/home/Home.vue'),
    },
    {
      path: '/bilder',
      name: 'bilder-home',
      component: () => import('./views/home/Home.vue'),
    },
    {
      path: '/aemnesord',
      name: 'keyword-home',
      component: () => import('./views/home/Home.vue'),
    },
    {
      path: '/bilder/:extId-:headline',
      name: 'image',
      component: () => import('./views/illustration/Illustration.vue'),
    },
    {
      path: '/search-result',
      name: 'library-search',
      component: () => import('./views/library/LibrarySearch/LibrarySearch.vue'),
    },
    {
      path: '/kategorier',
      name: 'library-start',
      component: () => import('./views/library/LibraryStart.vue'),
    },
    {
      path: '/kategorier/:category+',
      name: 'library',
      component: () => import('./views/library/Library.vue'),
    },
    {
      path: '/aemnesord/:keyword?',
      name: 'keyword',
      component: () => import('./views/keywordPage/KeywordPage.vue'),
    },
    {
      path: '/newsletter-link',
      name: 'newsletter',
      component: () => import('./views/newsletter/Newsletter.vue'),
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: () => import('./views/errors/404.vue'),
    },
    {
      path: '/terms',
      name: 'terms',
      meta: {
        data: 'terms',
      },
      component: () => import('./views/about/About.vue'),
    },
    {
      path: '/blog',
      name: 'blog',
      meta: {
        data: 'blog',
      },
      component: () => import('./views/about/About.vue'),
    },
    {
      path: '/news',
      name: 'news',
      meta: {
        data: 'news',
      },
      component: () => import('./views/about/About.vue'),
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        data: 'about',
      },
      component: () => import('./views/about/About.vue'),
    },
    {
      path: '/kontakt',
      name: 'contact',
      meta: {
        data: 'contact',
      },
      component: () => import('./views/contact/Contact.vue'),
    },
    {
      path: '/checkoutUrl',
      name: 'checkout',
      redirect: {
        path: '/checkout/information',
      },

      component: () => import('./views/checkout/Checkout.vue'),
      children: [
        {
          path: 'cart-summary',
          name: 'cartSummary',
          component: () => import('./views/checkout/views/CartSummary.vue'),
        },
        {
          path: 'type',
          name: 'checkoutCustomerType',
          component: () => import('./views/checkout/views/CustomerType.vue'),
        },
        {
          path: 'information',
          name: 'checkoutInformation',
          component: () => import('./views/checkout/views/CustomerInformation.vue'),
        },
        {
          path: 'summary',
          name: 'orderSummary',
          component: () => import('./views/checkout/views/OrderSummary.vue'),
        },
      ],
    },
    {
      path: 'checkoutUrl/payment/:ref',
      name: 'payment',
      component: () => import('./views/payment/Payment.vue'),
    },

    {
      path: 'checkoutUrl/confirm/:ref',
      name: 'orderConfirmation',
      component: () => import('./views/confirmation/Confirmation.vue'),
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }; // this can be adjusted to keep the scroll position
    }
  },
});

// Always scroll top between routes
router.beforeEach((to, from, next) => {
  document.getElementById('app').scrollTop = 0;
  next();
});

export default router;
