/* eslint-disable import/prefer-default-export */
const IMAGE_BASE_URL = 'https://s3.eu-central-1.amazonaws.com/nova-web-images';

/**
 *
 * @param {*} id Id of the image
 * @param {*} size mini, medium or maxi
 */
const resolveImageUrl = (id, size, htmlTitle) => {
  if (!['mini', 'medium', 'maxi'].includes(size.toLowerCase())) {
    throw new Error('Invalid size', size);
  }

  switch (size) {
    case 'mini':
      return `${IMAGE_BASE_URL}/${size.toLowerCase()}/${id}-mini.jpg`;
    case 'medium':
      return `${IMAGE_BASE_URL}/${size.toLowerCase()}/${id}-${htmlTitle.toLowerCase()}.jpg`;
    case 'maxi':
      return `${IMAGE_BASE_URL}/${size.toLowerCase()}/${id}-maxi.jpg`;
    default:
      return `${IMAGE_BASE_URL}/${size.toLowerCase()}/${id}-maxi.jpg`;
  }
};

export { resolveImageUrl };
